
@media screen and (max-width:1699.98px) {

    .left-section-img img { width: 70%;}
    .quickbooks-logo { width: 235px;}
    .right-box{padding-left: 70px;  }
    .box-one{
      margin-right: 15px;
    }
  }
  @media screen and (max-width: 991.98px){
   
    .right-box {
      padding-left: 20px;
      padding-right: 20px;
  }
  .quickbooks-logo {
    width: 50%;
  }
  .login-app-link {
    padding: 30px 15px 30px 15px;
  }
  .sign-in-intuit-black-btn{
    width: 75% ;
  }
  .select-input{
    width: 500px !important;
  }
  .copylink-btn{
    margin-top: 15px !important;
  }
  .reconciation-page .select-input {
    width: 100% !important;
}
.pr-none.MuiGrid-root {
  padding-right: 0;
}
.css-12eem4h-MuiTypography-root{
  padding: 20px !important;
}
/*----*/
.content-detail{
  margin-left: 0px !important;
}
.toggle-menu {display: block !important;}
.header-logo.MuiBox-root {height: auto !important;}
.toggle-menu .MuiBox-root {margin-left: 0;}
.toggle-menu button svg {font-size: 34px !important;}
.toggle-menu button {padding: 0;}    
.toggle-menu {padding: 0 0px 0 15px !important;}
.header-logo {margin-left: auto !important; margin-right: auto !important;}   
.MuiTypography-root.MuiTypography-h6.css-1aag1zc-MuiTypography-root {
    display: none;
}
.sidebar-menu-mobile-view .MuiPaper-root {
  top: 64px;
}

.sidebar-menu {
  padding-top: 0 !important;
}
.css-7cjgu9-MuiBackdrop-root-MuiModal-backdrop {
  top: 64px !important;
}
.sidebar-menu-mobile-view {
  width: 100% !important;
}

.sidebar-menu-mobile-view .MuiPaper-root {
  width: 100%;
  height: -webkit-fill-available !important;
}

.sidebar-menu {
  width: 100% !important;
  min-width: 100% !important;
  height: calc(100% - 1em) !important;
}
.side-menu{
  height: auto !important;
}
.get-support-box{
  display: none;
}
.MuiBackdrop-root {
  top: 50px !important;
}
.css-1u2w381-MuiModal-root-MuiDrawer-root {
  position: inherit !important;
}

/*-----*/
  }
  @media screen and (max-width:834.98px) {
    .read-our-mainbox {display: block;}  
  .read-our-box {text-align: center;}
  .box-one{margin-bottom: 20px;margin-right: 0;}
    
   
   
    .header-logo{
        margin-bottom: -6px;
    }
    .processing-text {
      width: 145px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  }

  @media screen and (max-width:767.98px){
    .notification-box{
      display: block;
    }
    .notification-box > div {
      margin-bottom: 5px;
  }
    .sign-in-btn {width: 100%; }
    .login-app-link a{font-size: 14px; }
    .login-app-link { padding: 30px 15px 0px 15px;}
    .header-logo {
        width: 47%;
    }
    .select-input{
      width: 300px !important;
    }
    .search-box {
      width: 100%;
     
  }
  }
  @media screen and (max-width:599.98px){
    .MuiBackdrop-root {
        top: 108px !important;
    }
    .login-app-link a{
      display: block;
      text-align: center;
    }
    .login-app-link {
      padding: 30px 15px 30px 15px;
  }
  /* .left-section-img {
    display: none;
  } */
  .login-box {
    height: auto;
    padding: 25px;
  }
  .right-box {
    align-items: flex-start;
    margin: 30px 0;
  }
  .left-section-img img {
    width: 50%;
  }
  .left-section-img img {
    width: 50%;
  }
  .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.css-m9a1j-MuiGrid-root,
  header {
    height: auto !important;
  }
  .parag-text, .sign-in-text {
    text-align: center;
  }

.header-logo {
    width: 88%;
    text-align: center;
    position: absolute;
    top: 6px;
    left: 0;
    right: 0;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-16rofud-MuiToolbar-root {
   
    justify-content: space-between;
    padding-top: 30px !important;
}

.client-profile button#basic-button {
    padding-right: 0;
}
/* .content-detail{
    padding: 135px 15px 15px 15px !important;
} */
.content-detail > div > div {
  padding: 15px 15px 15px 15px !important;
}
.content-detail{
  padding-top: 115px !important;
}
.sidebar-menu-mobile-view .MuiPaper-root,
.css-7cjgu9-MuiBackdrop-root-MuiModal-backdrop{
    top: 111px !important;
}
  }
  
  @media screen and (max-width:599.98px) {
    .left-section-img img {
      width: 70%;
  }
  .btn-box {
    margin-top: 40px;
  }
  .SmallText{
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .ExtraLargeText {
    font-size: 20px !important;
    line-height: 28px !important;
  }
  .width-100 {
    width: 100% !important;
}
.checked-box .MuiFormControlLabel-label{
  font-size: 14px !important;
  line-height: 20px !important;
}
.btn{
  padding: 8px 15px !important;
}
  }
  @media screen and (max-width:379.98px) {
    .automation-btn-box button {
      width: 100% !important;
      display: block !important;
      margin-left: 0px !important;
  }
  
  .automation-btn-box, .automation-btn-box > div {
      display: block !important;
  }
  
  .automation-btn-box .save-btn {
      margin-bottom: 25px !important;
  }
  }